import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TeamTile from './TeamTile';
import { Container, } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "nowrap",
    },
    calenderSlider: {
        color: '#ffffff !important',
        height: theme.spacing(20.5),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 3.5)
        },
        '& .slick-slider': {
            height: "100%"
        },
    },
    gameSlider: {
        color: '#ffffff !important',
        [theme.breakpoints.down('xs')]: {
        },
        '& .slick-slider': {
            height: "100%"
        },
    },
    arrowIcon: {
        fontSize: theme.spacing(6),
        color: "white !important",
        justifyContent: "center",
        alignItems: "center"
    }

}));

const TeamSlider = (props) => {
    const classes = useStyles();
    const [Initial, setInitial] = useState(props.Initial !== undefined ? props.Initial : 0);
    const [Infinite, setInfinite] = useState(false);

    const settings = {
        infinite: Infinite,
        speed: 1000,
        slidesToShow: props.dashboard ? 9 : 11,
        slidesToScroll: 6,
        initialSlide: props.Initial !== undefined ? props.Initial : 0,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: props.dashboard ? 6 : 11,
                    slidesToScroll: 6,
                    infinite: Infinite,
                    speed: 1000,
                    initialSlide: props.Initial !== undefined ? props.Initial : 0,
                    centerMode: false,
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: props.dashboard ? 6 : 9,
                    slidesToScroll: 6,
                    infinite: Infinite,
                    speed: 1000,
                    initialSlide: props.Initial !== undefined ? props.Initial : 0,
                    centerMode: false,
                }
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: props.dashboard ? 5 : 7,
                    slidesToScroll: 5,
                    infinite: Infinite,
                    speed: 1000,
                    initialSlide: props.Initial !== undefined ? props.Initial : 0,
                    centerMode: false,
                }
            },
            {
                breakpoint: 824,
                settings: {
                    infinite: Infinite,
                    speed: 1000,
                    slidesToShow: props.dashboard ? 5 : 7,
                    slidesToScroll: 5,
                    initialSlide: props.Initial !== undefined ? props.Initial : 0,
                    centerMode: false,
                }
            },
            {
                breakpoint: 812,
                settings: {
                    infinite: Infinite,
                    speed: 500,
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    initialSlide: props.Initial !== undefined ? props.Initial : 0,
                    centerMode: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    infinite: Infinite,
                    speed: 500,
                    slidesToShow: props.dashboard ? 5 : 7,
                    slidesToScroll: 5,
                    initialSlide: props.Initial !== undefined ? props.Initial : 0,
                    centerMode: false,
                }
            },
            {
                breakpoint: 720,
                settings: {
                    infinite: Infinite,
                    speed: 500,
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    initialSlide: props.Initial !== undefined ? props.Initial : 0,
                    centerMode: false,
                }
            },
            {
                breakpoint: 668,
                settings: {
                    infinite: Infinite,
                    speed: 500,
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    initialSlide: props.Initial !== undefined ? props.Initial : 0,
                    centerMode: false,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    infinite: Infinite,
                    speed: 500,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: props.Initial !== undefined ? props.Initial : 0,
                    centerMode: false,
                }
            },
            {
                breakpoint: 568,
                settings: {
                    infinite: Infinite,
                    speed: 500,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: props.Initial !== undefined ? props.Initial : 0,
                    centerMode: false,
                }
            },
            {
                breakpoint: 540,
                settings: {
                    infinite: Infinite,
                    speed: 500,
                    slidesToShow: props.dashboard ? 4 : 5,
                    slidesToScroll: 4,
                    initialSlide: props.Initial !== undefined ? props.Initial : 0,
                    centerMode: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    infinite: Infinite,
                    speed: 500,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: props.Initial !== undefined ? props.Initial : 0,
                    centerMode: false,
                }
            }
        ]
    }
    const [Teams, setTeams] = useState([]);
    const [Editions, setEditions] = useState([]);

    useEffect(() => {
        // if (props.type === "team")
        //     setInfinite(true);
        // else if (props.type === "edition")
        //     setInfinite(false);
    }, [props.type]);

    useEffect(() => {
        setTeams(props.Teams);
    }, [props.Teams]);

    useEffect(() => {
        setEditions(props.Editions);
    }, [props.Editions]);

    return (
        <Container maxWidth={props.dashboard ? false : "lg"}
            className={props.dashboard ? `${classes.gameSlider} gameSlider` : classes.calenderSlider}
            // key={`xy${props.forceKey}z`}
            >
            {props.type == "team" && Teams !== undefined && Teams.length > 0 ?
                <Slider {...settings}>
                    {props.Teams.map((item, index) => {
                        if (item.teamShortCode != null)
                            return <div>
                                <TeamTile dashboard={props.dashboard} type={props.type} data={item} key={item.teamId} onClick={(teamId) => {
                                    props.populateGames(item.teamId, item.teamNameSEO, index);
                                }} isActive={props.ActiveState !== undefined && props.ActiveState.length > 0 ? props.ActiveState[index] : null} />
                            </div>
                    })}
                </Slider> : null
            }
            {props.type == "edition" && Editions !== undefined && Editions.length > 0 ?
                <Slider {...settings} className={"wrapper left-align-slick"}>
                    {Editions.map((item, index) => {
                        if ((item.editioN_ID !== null || item.editioN_ID !== undefined) && item.speciaL_EDITION === false)
                            return <div>
                                <TeamTile dashboard={props.dashboard} type={props.type} data={item} key={item.editioN_ID} onClick={(editioN_ID) => {
                                    props.populateGames(editioN_ID, item.editioN_NAME_SEO, index);
                                }} isActive={props.ActiveState !== undefined && props.ActiveState.length > 0 ? props.ActiveState[index] : null} />
                            </div>
                    })}
                </Slider> : null
            }
        </Container>
    )
}
export default TeamSlider;