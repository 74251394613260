import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { withRouter, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Typography from '@material-ui/core/Typography';

import AppContext from '../common/AppContext';
import RouteConstants from "../common/RouteConstants";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    menuLabel: {
        color: "#b5b5b5",
        alignItems: 'center',
        fontSize: '0.75rem',
        marginLeft: theme.spacing(2.5),
        borderBottom: '3px solid transparent',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
            marginLeft: theme.spacing(1),
        },
    },
    highlightedMenu: {
        color: '#b5b5b5',
        alignItems: 'center',
        background: "transparent",
        fontSize: '0.75rem',
        marginLeft: theme.spacing(2.5),
        borderBottom: '3px solid #c8102e',
        borderRadius: '0',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
            marginLeft: theme.spacing(1),
        },
    },
    root: {
        flexGrow: 1,
    },
    megaMenuBg: {
        '& .MuiPaper-root': {
            border: '1px solid rgba(255, 255, 255, .3)',
            background: '#575757 !important',
            '& .MuiListItem-button': {
                '&:hover': {
                    background: '#413e3e',
                },
                '& .MuiTypography-root': {
                    color: '#fff',
                },
            },
        },
    },
}));

const EditionsMenu = (props) => {
    const classes = useStyles();
    const { allEditions } = useContext(AppContext);
    const [open, setOpen] = React.useState(false);
    const [selectedTab, setSelectedTab] = useState();

    useEffect(() => {
        setSelectedTab(props.selectedTab);
    }, [props.selectedTab]);

    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    let allEditionsUI = allEditions && allEditions.length > 0 ? allEditions.filter(e => e.speciaL_EDITION === false).map(edition => (
        <MenuItem
            onClick={() => {
                props.setHighlightedMenu("edition");
                let editionIndex = allEditions.findIndex(t => t.editioN_NAME_SEO === edition.editioN_NAME_SEO);
                sessionStorage.setItem("activeTileEdition", editionIndex !== -1 ? editionIndex : 0);
                setOpen(false);
                props.history.push({
                    pathname: RouteConstants.LOCKER_BY_EDITION + "/" + edition.editioN_NAME_SEO,
                    state: {
                        team: "",
                        edition: "edition",
                        fetchEditionData: true,
                        editionName: edition.editioN_NAME_SEO,
                        editionId: edition.editioN_ID,
                        editionIndex: editionIndex
                    }
                });
            }}>
            <Typography variant='caption'>{edition.editioN_NAME}</Typography>
        </MenuItem>
    )) : null;

    return (
        <div className={classes.root}>
            <div>
                <Button
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    className={selectedTab === "Edition" ? classes.highlightedMenu : classes.menuLabel}
                    style={{ cursor: 'pointer' }}
                    aria-haspopup="true"
                    color={props.buttonColor}
                    onClick={handleToggle}
                    //onMouseOver={handleToggle}
                >
                    Editions
                </Button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} className={classes.megaMenuBg} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        {allEditionsUI}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}

export default withRouter(EditionsMenu);
